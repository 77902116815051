import React, { useEffect, useState } from "react"
import { useShopify } from "@app/hooks/useShopify"
import { useProductContext } from "@app/providers/product"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AspectRatio,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react"
import { Icon } from "@app/components/Icon"
import { NormalisedCollection } from "@root/types/custom-types/Collection/Collection"
import { FilterItem } from "@root/types/custom-types/Personaliser/Data"
import { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import ProductItem from "./ProductItem"
import { OptionsAdditional } from "./ProductOptions"

type Props = {
  options: OptionsAdditional
  giftBoxCollections: any[]
  product: any
  stepNumber: any
}

export function ProductSelectGiftBox({ giftBoxCollections, product, options, stepNumber }: Props) {
  const { giftBoxSelectorModalOpen, setGiftBoxSelectorModalOpen, setProductBundle, productBundle, quantity, setQuantity, setMoq } =
    useProductContext()
  const { getCollection, getHandle } = useShopify()

  const [collections, setCollections] = useState<NormalisedCollection[]>([])
  const [activeCategory, setActiveCategory] = useState("")
  const [items, setItems] = useState<NormalisedProduct[] | undefined>([])
  const [showCustomBrandedSuccess, setShowCustomBrandedSuccess] = useState(false)
  const [customBrandedSuccessMessage, setCustomBrandedSuccessMessage] = useState(null)

  const fetchData = async () => {
    if (!giftBoxCollections) return

    setCollections([])

    const collectionResults = await Promise.all(
      giftBoxCollections.map(collection =>
        getCollection({
          firstProducts: 50,
          firstVariants: 1,
          firstImages: 2,
          firstMedia: 2,
          firstCollections: 2,
          firstMetafields: 2,
          handle: getHandle(collection),
        })
      )
    )

    if (collectionResults.length) setCollections(collectionResults)
  }

  const filtersList = giftBoxCollections
    ?.map(collection => {
      if (!collection.title || !collection.shopify?.handle) return

      return {
        label: collection.title,
        value: collection.shopify?.handle,
      }
    })
    .filter((c): c is FilterItem => c !== undefined)

  const optionProduct = productBundle.step5 ? productBundle.step5[0] : undefined

  const onOpenHandler = () => {
    setShowCustomBrandedSuccess(false)
    setGiftBoxSelectorModalOpen(true)
  }
  const onCloseHandler = () => setGiftBoxSelectorModalOpen(false)
  const onFilterClick = (value: string) => setActiveCategory(value)
  const onAddClickHandler = (product: NormalisedProduct) => {
    const isCustomBranded = !!product.tags?.find(tag => tag.includes("custom-branded"))
    const hasMoqTag = product.tags?.find(tag => tag.includes("moq"))
    if (hasMoqTag) {
      const moq = hasMoqTag.split(":")[1]
      setMoq(parseInt(moq))
      if (quantity < moq) {
        setCustomBrandedSuccessMessage(options.customGiftBoxMoqText.replace("{moq}", moq))
        setQuantity(moq)
      } else {
        setCustomBrandedSuccessMessage(null)
      }
    } else {
      setMoq(1)
      setCustomBrandedSuccessMessage(null)
    }
    setProductBundle(prevBundle => {
      return {
        ...prevBundle,
        ...{
          step5: [product], // giftbox was hardcoded as step5
        },
      }
    })
    if (isCustomBranded) {
      setShowCustomBrandedSuccess(true)
    } else {
      onCloseHandler()
    }
  }

  // intentionally only run once at first render
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftBoxCollections])

  useEffect(() => {
    const initialHandle = giftBoxCollections?.length ? giftBoxCollections[0].shopify?.handle || "" : ""
    setActiveCategory(initialHandle)
  }, [giftBoxCollections])

  useEffect(() => {
    if (!collections.length) return
    const activeCollection = collections.find(collection => collection.handle === activeCategory) || collections[0]
    setItems(activeCollection.products || [])
  }, [collections, activeCategory, setItems])

  const buttonProps = options.overrideSelectGiftBoxButtonColors
    ? {
        borderColor: options.selectGiftBoxButtonColors?.borderColor?.hex || options.selectGiftBoxButtonColors?.backgroundColor?.hex,
        backgroundColor: options.selectGiftBoxButtonColors?.backgroundColor?.hex,
        color: options.selectGiftBoxButtonColors?.textColor?.hex,
        _hover: {
          backgroundColor: options.selectGiftBoxButtonColors?.hoverBackgroundColor?.hex,
          borderColor: options.selectGiftBoxButtonColors?.hoverBackgroundColor?.hex,
        },
        _active: {
          backgroundColor: options.selectGiftBoxButtonColors?.hoverBackgroundColor?.hex,
          borderColor: options.selectGiftBoxButtonColors?.hoverBackgroundColor?.hex,
        },
      }
    : {}

  return (
    <Box mb={{ base: "5", lg: "6" }}>
      <Text size="smSemiLarge" color="typography.headlines900" mb="1.5">
        {options.selectGiftBoxLabel.replace("{number}", stepNumber)}
      </Text>
      {optionProduct ? (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          py="2"
          pr="4"
          pl="2"
          border="1px solid"
          borderColor="border.light"
          boxShadow="subtleBottomGlow"
          borderRadius="xl"
          cursor="pointer"
        >
          <Flex gap="2" alignItems="center">
            <AspectRatio ratio={1 / 1} w="10.5" h="10.5">
              <Image {...optionProduct.images[0]} />
            </AspectRatio>

            <Text maxWidth="40" size="textBase">
              {optionProduct.title}
            </Text>
          </Flex>

          <Button
            fontWeight={"normal"}
            colorScheme="black"
            textTransform={"capitalize"}
            textDecoration={"underline"}
            variant={"link"}
            onClick={onOpenHandler}
            fontSize="textBase"
            color="typography.body600"
          >
            {options.editGiftBoxLabel}
          </Button>
        </Flex>
      ) : (
        <Button {...buttonProps} onClick={onOpenHandler} variant="solidSecondary" size="lg" w="full">
          {options.selectGiftBoxButtonLabel}
        </Button>
      )}
      <Modal onClose={onCloseHandler} isOpen={giftBoxSelectorModalOpen} size="option1">
        <ModalOverlay bg="background.overlay" />

        <ModalContent>
          <ModalBody p="0">
            <IconButton
              onClick={onCloseHandler}
              variant="unstyled"
              icon={<Icon name="ui/close" width="24px" height="24px" />}
              aria-label={"Close Drawer"}
              pos="absolute"
              top="4"
              right="4"
              bg="background.white"
              zIndex="overlay"
              d="inline-flex"
              justifyContent="center"
              alignItems="center"
              w="10"
              h="10"
              borderRadius="full"
              boxShadow="subtleBottomGlow"
              _focus={{ boxShadow: "subtleBottomGlow" }}
            />

            <VStack h="full" spacing="0">
              {showCustomBrandedSuccess ? (
                <CustomBrandedSuccessContent
                  message={customBrandedSuccessMessage}
                  handleClose={onCloseHandler}
                  heading={product.customGiftBoxSelectedHeading}
                  text={product.customGiftBoxSelectedText}
                />
              ) : (
                <>
                  <Box
                    w={{ base: "100%", md: "calc(100% - 96px)" }}
                    pt={{ base: "6", lg: "12" }}
                    pb={{ lg: "6" }}
                    borderBottom={{ lg: "solid 1px" }}
                    borderColor={{ lg: "border.default" }}
                    mx={{ lg: "auto" }}
                    textAlign="center"
                  >
                    <Box w="full" px={{ base: "6", lg: "12" }} mb={{ base: "3", lg: "6" }}>
                      <Heading size="h4" color="typography.headlines900" maxW="150" mx="auto" mb={{ md: "1.5" }}>
                        Step 4. Select Gift Box
                      </Heading>

                      <Text size="largeParagraph" maxW="150" mx="auto">
                        Choose from our life occasions or custom branded gift boxes!
                      </Text>
                    </Box>

                    {filtersList ? (
                      <Box overflow="hidden" w="full">
                        <Flex
                          overflow="auto"
                          scrollSnapType="x mandatory"
                          scrollPadding={{ base: "4", lg: "0" }}
                          sx={{
                            scrollbarWidth: { base: "none" },
                            "::-webkit-scrollbar": {
                              display: { base: "none" },
                            },
                          }}
                          pb="1"
                          justifyContent="center"
                        >
                          {filtersList.map(item => {
                            const isActive = item?.value === activeCategory

                            return item?.value ? (
                              <Text
                                key={item.value}
                                as="span"
                                d="inline-block"
                                onClick={() => onFilterClick(item?.value)}
                                size="textBase"
                                flexShrink={0}
                                scrollSnapAlign="start"
                                py="1.5"
                                px="4.5"
                                mr="3"
                                boxShadow="subtleBottomGlow"
                                border="1px solid"
                                borderRadius="3xxl"
                                cursor="pointer"
                                _first={{ ml: { base: "4", lg: "0" } }}
                                _last={{ mr: { base: "4", lg: "0" } }}
                                fontWeight={isActive ? "bold" : "normal"}
                                color={isActive ? "background.white" : "typography.headlines900"}
                                bg={isActive ? "brand.primary" : "background.white"}
                                borderColor={isActive ? "transparent" : "border.light"}
                              >
                                {item?.label}
                              </Text>
                            ) : null
                          })}
                        </Flex>
                      </Box>
                    ) : null}
                  </Box>

                  <Box flex={{ md: 1 }} overflow={{ md: "auto" }}>
                    <Grid
                      gridTemplateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
                      gap={{ base: "4", lg: "5" }}
                      py={{ base: "4", md: "6" }}
                      px={{ base: "4", md: "8", lg: "12" }}
                    >
                      {items && items.length
                        ? items.map(product => <ProductItem key={product.id} item={product} onAddHandler={onAddClickHandler} />)
                        : [...Array(4)].map((i, index) => <Skeleton key={index} isLoaded={false} w="full" pb="calc(100% + 188px)" />)}
                    </Grid>
                  </Box>
                </>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

function CustomBrandedSuccessContent({
  handleClose,
  heading = "Custom branded gift box added!",
  text = "We will email you for the branding files or templates",
  message,
}) {
  console.log("message :>> ", message)
  return (
    <>
      <Flex
        px={{
          base: "4",
          lg: "12",
        }}
        flexDirection="column"
        h="full"
        alignItems="center"
        justifyItems="center"
        justifyContent="center"
      >
        {message ? (
          <Alert p="2" status="info" mt="-16" mb="16">
            <AlertIcon />
            <AlertDescription>{message}</AlertDescription>
          </Alert>
        ) : null}
        <Icon name="ui/check-circle" width="48px" height="48px" />
        <Heading size="h4" color="typography.headlines900" mt="4">
          {heading}
        </Heading>
        <Text size="largeParagraph" color="typography.body600" my="14">
          {text}
        </Text>
        <Button w="full" size="lg" variant="solidSecondary" onClick={handleClose}>
          Continue
        </Button>
      </Flex>
    </>
  )
}
