import React from "react"
import { Box, Flex, Radio, RadioGroup, Text } from "@chakra-ui/react"
import { useProductContext } from "@app/providers/product"
import { Switch } from "@chakra-ui/react"

export function ProductMultipleShippingLocations({ options, stepNumber }) {
  const { isMultipleShippingLocations, setIsMultipleShippingLocations } = useProductContext()

  const handleChange = event => {
    setIsMultipleShippingLocations(event === "yes")
  }
  return (
    <Box p={{ base: "4", lg: "4" }} mb={{ base: "5", lg: "6" }} boxShadow="subtleBottomGlow" borderRadius="xl">
      <Box mb={{ base: "5", lg: "6" }}>
        <Text size="smSemiLarge" color="typography.headlines900" mb="1.5">
          {options.multipleShippingLocationLabel.replace("{number}", stepNumber)}
        </Text>
        {options.multipleShippingLocationDescription ? (
          <Text size="mediumParagraph" mb="3">
            {options.multipleShippingLocationDescription}
          </Text>
        ) : null}
      </Box>
      <Flex width={"100%"} justifyItems={"center"} justifyContent="start">
        {/* <Switch isChecked={isMultipleShippingLocations} onChange={event => setIsMultipleShippingLocations(event.target.checked)} /> */}
        <RadioGroup
          defaultValue={isMultipleShippingLocations ? "yes" : "no"}
          name="multipleShippingLocations"
          onChange={handleChange}
          display="flex"
          gap="4"
        >
          <Radio value={"yes"}>Yes</Radio>
          <Radio value={"no"}>No</Radio>
        </RadioGroup>
      </Flex>
    </Box>
  )
}
