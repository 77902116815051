import React from "react"
import { Box, Button, Flex, NumberInput, NumberInputField, Spacer, Text } from "@chakra-ui/react"
import { BiMinus, BiPlus } from "react-icons/bi"
import { useProductContext } from "@app/providers/product"

export function ProductQuantitySelector({ options, min = 1, stepNumber }) {
  const handleNumberInputBlur = e => {
    const value = parseInt(e.target.value, 10)
    if (e.target.value === "") {
      setQuantity(1)
    } else if (value < min) {
      setQuantity(min)
    }
  }
  const { quantity, setQuantity } = useProductContext()
  return (
    <Box mb={{ base: "5", lg: "6" }}>
      <Text size="smSemiLarge" color="typography.headlines900" mb="1.5">
        {options.selectQuantityLabel.replace("{number}", stepNumber)}
      </Text>
      <Flex width={"100%"} justifyItems={"center"} justifyContent="center">
        <Button
          variant="outlineSecondary"
          disabled={quantity <= min}
          onClick={() => setQuantity(prevQuantity => (prevQuantity <= 1 ? 1 : parseInt(`${prevQuantity}`, 10) - 1))}
        >
          <BiMinus />
        </Button>
        <Spacer />
        <NumberInput inputMode="numeric" keepWithinRange clampValueOnBlur value={quantity} defaultValue={1} min={min}>
          <NumberInputField
            onBlur={handleNumberInputBlur}
            onChange={e => setQuantity(parseInt(e.target.value, 10) || e.target.value)}
            textAlign={"center"}
          />
        </NumberInput>
        <Spacer />
        <Button
          variant="outlineSecondary"
          onClick={() => setQuantity(prevQuantity => (prevQuantity === "" || prevQuantity < 1 ? 1 : parseInt(`${prevQuantity}`, 10) + 1))}
        >
          <BiPlus />
        </Button>
      </Flex>
    </Box>
  )
}
