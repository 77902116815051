import React from "react"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"

import { YotpoStoreReview } from "@app/hooks/useReviews"
import ReviewRating from "@app/components/Sections/Reviews/ReviewRating"
import { ReviewTheme, SectionColors } from "@app/components/Sections/Reviews//Reviews"

type Props = {
  review: YotpoStoreReview
  theme: ReviewTheme
  sectionColors: SectionColors
}

const ReviewItem: React.FC<Props> = ({ review, theme, sectionColors }) => {
  const {
    score,
    created_at,
    title,
    content,
    user: { display_name },
  } = review

  const formattedDate = new Date(created_at).toLocaleDateString("en-AU")

  return content ? (
    <Box textAlign="left">
      <Flex justifyContent="flex-start" alignItems="center" mb="2" gap="2">
        <Box as="span">
          <ReviewRating score={score} />
        </Box>

        {formattedDate && (
          <Text size="mediumParagraph" color={sectionColors.body[theme]}>
            {formattedDate}
          </Text>
        )}
      </Flex>

      {title && (
        <Heading as="h5" size="h5" mb="2" color={sectionColors.heading[theme]}>
          {title}
        </Heading>
      )}

      <Text size="largeParagraph" mb="2" color={sectionColors.body[theme]}>
        {content}
      </Text>

      {display_name && (
        <Text size="headlines" color={sectionColors.heading[theme]}>
          {display_name}
        </Text>
      )}
    </Box>
  ) : null
}

export default React.memo(ReviewItem)
