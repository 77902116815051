import React, { useMemo } from "react"

import { NormalisedImage, NormalisedShopifyProduct, useShopify } from "@app/hooks/useShopify"
import { useMedia } from "@app/hooks/useMedia"
import ProductMediaSwiper from "@app/components/Product/ProductMediaSwiper"
import ProductMediaGrid from "@app/components/Product/ProductMediaGrid"

type Props = {
  product: NormalisedShopifyProduct
}

const ProductMedia: React.FC<Props> = ({ product }) => {
  const { imageNormaliser } = useShopify()
  const { isLarge } = useMedia()

  const images: NormalisedImage[] = useMemo(
    () => product?.images?.map((image: any) => imageNormaliser(image, 1200)).filter(i => i) || [],
    [product.images, imageNormaliser]
  )

  return images.length ? (
    !isLarge ? (
      <ProductMediaSwiper product={product} images={images} />
    ) : (
      <ProductMediaGrid product={product} images={images} />
    )
  ) : null
}

export default React.memo(ProductMedia)
