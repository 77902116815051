import React from "react"
import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react"
import ReactPlayer from "react-player"

export function ProductVideoExplainerModal({ isOpen, onClose, videoUrl, title }: any) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
      <ModalOverlay bg="background.overlay" />
      <ModalContent h="auto">
        <ModalHeader fontSize={"3xl"} textAlign={"center"}>
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="10">
          <Flex justifyContent="center" alignItems="center">
            <ReactPlayer
              config={{
                vimeo: {
                  playerOptions: {
                    quality: "720p",
                  },
                },
              }}
              fallback={
                <Flex alignItems="center" justifyContent="center">
                  <Text>Loading...</Text>
                </Flex>
              }
              url={videoUrl}
              controls
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
