import React, { useState } from "react"
import { Box, Heading, Text, RadioGroup, Radio, FormControl, Textarea, FormLabel } from "@chakra-ui/react"

import CustomAlert from "@app/components/CustomAlert"
import { MessageChangeHandler, RadioHandler } from "@app/components/Product/ProductOptions"
import { PersonaliserError } from "@root/types/custom-types/Personaliser/Data"
import { useProductContext } from "@app/providers/product"

type Props = {
  location: "personaliser" | "product"
  title: string | undefined
  description: string | undefined
  multipleDescription: string | undefined
  options: string[]
  isSelected: string
  label: string | undefined
  messagePlaceholder: string | undefined
  messageError?: PersonaliserError
  radioHandler: RadioHandler
  onMessageChangeHandler: MessageChangeHandler
  message: string | undefined
  additionalOption?: string
}

function PersonaliserGiftCardSelection({
  location,
  title,
  description,
  multipleDescription,
  options,
  isSelected,
  label,
  messagePlaceholder,
  messageError,
  radioHandler,
  onMessageChangeHandler,
  message,
  additionalOption,
}: Props) {
  const { quantity } = useProductContext()
  const [selectedOption, setSelectedOption] = useState(options[1])
  const selectionOptions = quantity > 1 ? [...options, additionalOption] : options
  const handleChange = (value: string) => {
    setSelectedOption(value)
    radioHandler(value, "giftcard")
  }
  return (
    <Box
      p={{ base: "4", lg: location !== "product" ? "8" : "4" }}
      mb={location !== "product" ? "0" : { base: "5", lg: "6" }}
      boxShadow="subtleBottomGlow"
      borderRadius="xl"
    >
      <Box mb={{ base: "3", lg: "6" }}>
        <Box>
          {title ? (
            <Heading size="headlines" color="typography.headlines900" mb="1">
              {title}
            </Heading>
          ) : null}

          {description ? <Text size="largeParagraph">{description}</Text> : null}
          {quantity > 1 ? <Text size="mediumParagraph">{multipleDescription}</Text> : null}
        </Box>
      </Box>

      {selectionOptions.length ? (
        <RadioGroup
          // key={`giftcard-${isSelected}`}
          defaultValue={isSelected === "true" ? selectionOptions[0] : selectionOptions[1]}
          name="giftcard"
          onChange={handleChange}
          display="flex"
          gap="4"
        >
          {selectionOptions.map(option => (
            <Radio key={option.toLowerCase()} value={option}>
              {option}
            </Radio>
          ))}
        </RadioGroup>
      ) : null}

      {selectedOption === options[0] ? (
        <Box mt="6">
          {label ? (
            <Text size="largeParagraph" mb="1.5">
              {label}
            </Text>
          ) : null}

          {messagePlaceholder ? (
            <FormControl>
              <Textarea placeholder=" " onChange={onMessageChangeHandler} value={message} />
              <FormLabel>{messagePlaceholder}</FormLabel>
            </FormControl>
          ) : null}
        </Box>
      ) : null}

      {messageError && messageError.active ? (
        <CustomAlert status="error" withoutIcon={true} mt="3">
          {messageError.message}
        </CustomAlert>
      ) : null}
    </Box>
  )
}
export default React.memo(PersonaliserGiftCardSelection)
