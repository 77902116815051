import React, { useCallback, useEffect, useMemo, useState } from "react"
import { VStack, Box, Heading, Flex, Grid, Text } from "@chakra-ui/react"

import PersonaliserLabelItem from "@app/components/Personaliser/PersonaliserLabelItem"

import { ModalImage, PersonaliserLabel, PersonaliserLabelCategory } from "@root/types/custom-types/Personaliser/Data"

type Props = {
  modalTitle: string
  modalDescription: string
  labelSubtitle: string
  enlargeProduct: string
  selectProduct: string
  labels: PersonaliserLabel[]
  onLabelEnlargeHandler: (images: ModalImage[] | undefined) => void
  onCloseHandler: () => void
  onLabelClickHandler: (item: PersonaliserLabel) => void
}

const ProductLabelSelectionGrid: React.FC<Props> = ({
  modalTitle,
  modalDescription,
  labelSubtitle,
  enlargeProduct,
  selectProduct,
  labels,
  onLabelEnlargeHandler,
  onLabelClickHandler,
}) => {
  const [activeCategory, setActiveCategory] = useState("")

  const filterCategories = useMemo(() => {
    const categoriesAll = labels?.map(label => label?.categories?.map(category => category)).flat()
    const uniqueCategories: PersonaliserLabelCategory[] = []
    categoriesAll?.forEach(category => {
      if (!category) return

      if (!uniqueCategories.find(cat => cat.handle?.current === category?.handle?.current)) uniqueCategories.push(category)
    })

    uniqueCategories.sort((a, b) => (a?.order > b?.order ? 1 : a?.order <= b?.order ? -1 : 0))

    return uniqueCategories
  }, [labels])

  const activeLabels = useMemo(
    () => labels.filter(label => label.categories.find(category => category.handle.current === activeCategory)),
    [labels, activeCategory]
  )

  const onFilterClick = useCallback((value: string) => setActiveCategory(value), [setActiveCategory])

  useEffect(() => setActiveCategory(filterCategories[0].handle.current || ""), [setActiveCategory, filterCategories])

  return (
    <>
      <VStack h="full" spacing="0">
        <Box
          w={{ base: "100%", md: "calc(100% - 96px)" }}
          pt={{ base: "6", lg: "12" }}
          pb={{ lg: "6" }}
          borderBottom={{ lg: "solid 1px" }}
          borderColor={{ lg: "border.default" }}
          mx={{ lg: "auto" }}
          textAlign="center"
        >
          <Box w="full" px={{ base: "6", lg: "12" }} mb={{ base: "3", lg: "6" }}>
            {modalTitle ? (
              <Heading size="h4" color="typography.headlines900" maxW="150" mx="auto" mb={{ md: "1.5" }}>
                {modalTitle}
              </Heading>
            ) : null}

            {modalDescription ? (
              <Text size="largeParagraph" maxW="150" mx="auto">
                {modalDescription}
              </Text>
            ) : null}
          </Box>

          {filterCategories.length ? (
            <Box overflow="hidden" w="full">
              <Flex
                overflow="auto"
                scrollSnapType="x mandatory"
                scrollPadding={{ base: "4", lg: "0" }}
                sx={{
                  scrollbarWidth: { base: "none" },
                  "::-webkit-scrollbar": {
                    display: { base: "none" },
                  },
                }}
                pb="1"
                justifyContent={{ md: "center" }}
              >
                {filterCategories.map(item => {
                  const isActive = item.handle.current === activeCategory

                  return (
                    <Text
                      key={item.handle.current}
                      as="span"
                      d="inline-block"
                      onClick={() => onFilterClick(item.handle.current)}
                      size="textBase"
                      flexShrink={0}
                      scrollSnapAlign="start"
                      py="1.5"
                      px="4.5"
                      mr="3"
                      boxShadow="subtleBottomGlow"
                      border="1px solid"
                      borderRadius="3xxl"
                      cursor="pointer"
                      _first={{ ml: { base: "4", lg: "0" } }}
                      _last={{ mr: { base: "4", lg: "0" } }}
                      fontWeight={isActive ? "bold" : "normal"}
                      color={isActive ? "background.white" : "typography.headlines900"}
                      bg={isActive ? "brand.primary" : "background.white"}
                      borderColor={isActive ? "transparent" : "border.light"}
                    >
                      {item.title}
                    </Text>
                  )
                })}
              </Flex>
            </Box>
          ) : null}
        </Box>

        <Box flex={{ md: 1 }} overflow={{ md: "auto" }}>
          <Grid
            gridTemplateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
            gap={{ base: "4", lg: "5" }}
            py={{ base: "4", md: "6" }}
            px={{ base: "4", md: "8", lg: "12" }}
          >
            {activeLabels.map(labelItem => (
              <PersonaliserLabelItem
                key={labelItem.title?.toLowerCase()}
                item={labelItem}
                textSubtitle={labelSubtitle}
                textEnlarge={enlargeProduct}
                textSelect={selectProduct}
                onClickHandler={onLabelClickHandler}
                onEnlargeHandler={onLabelEnlargeHandler}
              />
            ))}
          </Grid>
        </Box>
      </VStack>
    </>
  )
}
export default React.memo(ProductLabelSelectionGrid)
