import React, { useCallback, useEffect, useState } from "react"
import { Box } from "@chakra-ui/react"

import { SanityCTAColor, useShopify } from "@app/hooks/useShopify"
import { useProductContext } from "@app/providers/product"
import ProductOption1Selection from "@app/components/Product/ProductOption1Selection"
import ProductLabelSelection from "@app/components/Product/ProductLabelSelection"
import PersonaliserStickerSelection from "@app/components/Personaliser/PersonaliserStickerSelection"
import PersonaliserGiftCardSelection from "@app/components/Personaliser/PersonaliserGiftCardSelection"

import { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import { PersonaliserLabel, CustomiserData } from "@root/types/custom-types/Personaliser/Data"
import { ProductQuantitySelector } from "./ProductQuantitySelector"
import { ProductSelectGiftBox } from "./ProductSelectGiftBox"
import { ProductMultipleShippingLocations } from "./ProductMultipleShippingLocations"

export type RadioHandler = (value: string, type: "sticker" | "giftcard") => void
export type MessageChangeHandler = (e: React.SyntheticEvent) => void

export type ProductOptionsData = {
  option1: GatsbyTypes.SanityCollection[] | undefined
  option2: PersonaliserLabel[] | undefined
  giftbox: NormalisedProduct | undefined
}

export type OptionsAdditional = {
  option1Title: string
  option1Description: string
  labelModalTitle: string
  labelModalDescription: string
  selectOptionLabel: string
  selectOptionButtonLabel: string
  editOptionLabel: string
  selectLabelLabel: string
  selectLabelDescription: string | undefined
  requiredMessage: string
  personalisedButtonLabel: string
  enlargeProduct: string
  labelSubtitle: string
  selectProduct: string
  selectOptionButtonColors: SanityCTAColor
  personalisedButtonColors: SanityCTAColor
  selectGiftBoxButtonColors: SanityCTAColor
  overrideSelectOptionButtonColors: boolean
  overridePersonalisedButtonColors: boolean
  overrideSelectGiftBoxButtonColors: boolean
  enableQuantitySelector: string
  enableMultipleShippingLocation: string
  multipleShippingLocationLabel: string
  multipleShippingLocationDescription: string
  selectQuantityLabel: string
  enableSelectGiftBox: string
  selectGiftBoxLabel: string
  selectGiftBoxButtonLabel: string
  editGiftBoxLabel: string
  customGiftBoxSelectedHeading: string
  customGiftBoxSelectedText: string
  customGiftBoxMoqText: string
  increaseSizeOfDoNotOpenSticker: boolean
  additionalMessagingGiftCardOptions: string
  sticker: {
    title: string | undefined
    description: string | undefined
    product: GatsbyTypes.Maybe<GatsbyTypes.SanityProductFragmentFragment>
    options: string[]
    datePlaceholder: string
  }
  giftcard: {
    title: string | undefined
    description: string | undefined
    multipleDescription: string | undefined
    options: string[]
    label: string | undefined
    messagePlaceholder: string | undefined
    product: GatsbyTypes.Maybe<GatsbyTypes.SanityProductFragmentFragment>
  }
  labelFinaliseMessage: string
  labelFinaliseWarning: string
  labelTagLarge: string
  labelTagSmall: string
  videoExplainer?: {
    title: string
    videoUrl: string
    modalTriggerText: string
    enableVideoExplainer: string
  }
}

type Props = {
  optionsAdditional: OptionsAdditional
  productOptionsData: ProductOptionsData
  labels: PersonaliserLabel[]
  customiserData: CustomiserData
  product: any
}

function ProductOptions({ optionsAdditional, productOptionsData, labels, customiserData, product }: Props) {
  const { productBundle, setProductBundle, quantity, moq } = useProductContext()
  const { getProducts } = useShopify()

  const [stickerProduct, setStickerProduct] = useState<NormalisedProduct>()
  const [giftcardProduct, setGiftcardProduct] = useState<NormalisedProduct>()

  const productIsB2bFocused = product.tags.includes("focus:b2b")
  const showQtySelector = productIsB2bFocused
    ? ["b2b", "both"].includes(optionsAdditional?.enableQuantitySelector)
    : ["b2c", "both"].includes(optionsAdditional?.enableQuantitySelector)

  const showGiftBoxSelector = productIsB2bFocused
    ? ["b2b", "both"].includes(optionsAdditional?.enableSelectGiftBox)
    : ["b2c", "both"].includes(optionsAdditional?.enableSelectGiftBox)

  const showMultipleShippingLocation =
    quantity > 1
      ? productIsB2bFocused
        ? ["b2b", "both"].includes(optionsAdditional?.enableMultipleShippingLocation)
        : ["b2c", "both"].includes(optionsAdditional?.enableMultipleShippingLocation)
      : false

  const fetchData = useCallback(async () => {
    const stickerSanityProduct = optionsAdditional.sticker.product
    const giftcardSanityProduct = optionsAdditional.giftcard.product
    if (!stickerSanityProduct || !giftcardSanityProduct) return

    setStickerProduct(undefined)
    setGiftcardProduct(undefined)

    if (stickerSanityProduct) {
      const stickerResult = await getProducts({
        handles: [stickerSanityProduct.shopify?.handle as string],
        firstImages: 1,
        firstVariants: 1,
      })
      if (stickerResult.length) setStickerProduct(stickerResult[0])
    }

    if (giftcardSanityProduct) {
      const giftcardResult = await getProducts({
        handles: [giftcardSanityProduct.shopify?.handle as string],
        firstImages: 1,
        firstVariants: 1,
      })
      if (giftcardResult.length) setGiftcardProduct(giftcardResult[0])
    }
  }, [optionsAdditional.sticker.product, optionsAdditional.giftcard.product, setStickerProduct, setGiftcardProduct, getProducts])

  // intentionally only run once at first render
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const radioHandler = (value: string, type: "sticker" | "giftcard") => {
    setProductBundle(prevBundle => {
      const isMultiple = value.toLocaleLowerCase().includes("multiple")
      const updatedObject =
        type === "sticker"
          ? {
              sticker: {
                date: undefined,
                product: undefined,
                isSelected: value === "true",
              },
              giftcard: {
                ...prevBundle.messaging.giftcard,
              },
            }
          : {
              giftcard: {
                message: isMultiple ? "We will email you to collect details for multiple cards with different messages" : undefined,
                product: isMultiple ? giftcardProduct : undefined,
                isSelected: value === "true" || value.toLocaleLowerCase().includes("multiple"),
                isMultiple,
              },
              sticker: {
                ...prevBundle.messaging.sticker,
              },
            }

      return {
        ...prevBundle,
        ...{
          messaging: updatedObject,
        },
      }
    })
  }

  const onDateChangeHandler = (date: Date) => {
    setProductBundle(prevBundle => ({
      ...prevBundle,
      messaging: {
        ...prevBundle.messaging,
        sticker: {
          ...prevBundle.messaging.sticker,
          product: stickerProduct,
          date: date,
        },
      },
    }))
  }

  const onMessageChangeHandler = (e: React.SyntheticEvent) => {
    setProductBundle(prevBundle => ({
      ...prevBundle,
      messaging: {
        ...prevBundle.messaging,
        giftcard: {
          ...prevBundle.messaging.giftcard,
          product: giftcardProduct,
          message: (e.target as HTMLTextAreaElement).value || "",
        },
      },
    }))
  }

  const getAdditionalOptionsStepNumbers = () => {
    const stepNumbers = {
      quantitySelector: showQtySelector ? 3 : 0,
      giftBoxSelector: showQtySelector && showGiftBoxSelector ? 4 : showGiftBoxSelector ? 3 : 0,
      multipleShippingLocation:
        showQtySelector && showGiftBoxSelector && showMultipleShippingLocation
          ? 5
          : showQtySelector || (showGiftBoxSelector && showMultipleShippingLocation)
            ? 4
            : !showGiftBoxSelector && !showQtySelector && showMultipleShippingLocation
              ? 3
              : 0,
    }
    return stepNumbers
  }

  return (
    <Box>
      <ProductOption1Selection
        product={product}
        title={optionsAdditional.option1Title}
        description={optionsAdditional.option1Description}
        selectLabel={optionsAdditional.selectOptionLabel}
        selectButtonLabel={optionsAdditional.selectOptionButtonLabel}
        editLabel={optionsAdditional.editOptionLabel}
        optionCollections={productOptionsData.option1}
        options={optionsAdditional}
      />

      <ProductLabelSelection
        product={product}
        selectLabel={optionsAdditional.selectLabelLabel}
        description={optionsAdditional.selectLabelDescription}
        requiredMessage={optionsAdditional.requiredMessage}
        personalisedButtonLabel={optionsAdditional.personalisedButtonLabel}
        edit={optionsAdditional.editOptionLabel}
        labelProducts={productOptionsData.option2}
        labels={labels}
        modalTitle={optionsAdditional.labelModalTitle}
        modalDescription={optionsAdditional.labelModalDescription}
        enlargeProduct={optionsAdditional.enlargeProduct}
        labelSubtitle={optionsAdditional.labelSubtitle}
        selectProduct={optionsAdditional.selectProduct}
        customiserData={customiserData}
        labelFinaliseMessage={optionsAdditional.labelFinaliseMessage}
        labelFinaliseWarning={optionsAdditional.labelFinaliseWarning}
        labelTagLarge={optionsAdditional.labelTagLarge}
        labelTagSmall={optionsAdditional.labelTagSmall}
        options={optionsAdditional}
      />

      {showQtySelector ? (
        <ProductQuantitySelector stepNumber={getAdditionalOptionsStepNumbers().quantitySelector} min={moq} options={optionsAdditional} />
      ) : null}

      {showGiftBoxSelector ? (
        <ProductSelectGiftBox
          stepNumber={getAdditionalOptionsStepNumbers().giftBoxSelector}
          options={optionsAdditional}
          product={product}
          giftBoxCollections={product.giftBoxProductOptions}
        />
      ) : null}

      <Box borderBottom="1px solid" borderColor="border.default" mb={{ base: "5", lg: "6" }} />

      <PersonaliserStickerSelection
        {...optionsAdditional.sticker}
        increaseSizeOfSticker={optionsAdditional.increaseSizeOfDoNotOpenSticker}
        sanityProduct={product}
        location="product"
        isSelected={`${productBundle.messaging.sticker.isSelected}`}
        radioHandler={radioHandler}
        onDateChangeHandler={onDateChangeHandler}
        stickerDate={productBundle.messaging.sticker.date}
      />

      <PersonaliserGiftCardSelection
        {...optionsAdditional.giftcard}
        additionalOption={optionsAdditional.additionalMessagingGiftCardOptions}
        location="product"
        isSelected={`${productBundle.messaging.giftcard.isSelected}`}
        radioHandler={radioHandler}
        onMessageChangeHandler={onMessageChangeHandler}
        message={productBundle.messaging.giftcard.message}
      />
      {showMultipleShippingLocation ? (
        <ProductMultipleShippingLocations
          stepNumber={getAdditionalOptionsStepNumbers().multipleShippingLocation}
          options={optionsAdditional}
        />
      ) : null}
    </Box>
  )
}
export default React.memo(ProductOptions)
