import React, { useCallback, useMemo } from "react"
import { AspectRatio, Box, Center, Grid, GridItem, Image } from "@chakra-ui/react"

import { useProductContext } from "@app/providers/product"
import { NormalisedImage, NormalisedShopifyProduct, useShopifyProduct } from "@app/hooks/useShopify"
import { useImage } from "@app/hooks/useImage"
import ProductBadge from "@app/components/Product/ProductBadge"
import { Icon } from "@app/components/Icon"

type Props = {
  product: NormalisedShopifyProduct
  images: NormalisedImage[]
}

const ProductMediaGrid: React.FC<Props> = ({ product, images }) => {
  const { setIsImageModalOpen, setActiveIndex, setModalImages } = useProductContext()
  const { getGatsbyImage } = useImage()
  const { getProductTagValue } = useShopifyProduct()

  const onZoomClickHandler = useCallback(
    (index?: number) => {
      setModalImages(images)
      setIsImageModalOpen(true)
      if (index !== undefined) setActiveIndex(index)
    },
    [setIsImageModalOpen, setActiveIndex, setModalImages, images]
  )

  const formattedImages = useMemo(() => images.map(getGatsbyImage), [getGatsbyImage, images])
  const firstImage = useMemo(() => formattedImages[0], [formattedImages])
  const badge = useMemo(() => getProductTagValue("badge:", product.tags), [getProductTagValue, product.tags])

  return (
    <>
      <Box pos="relative">
        <Center
          as="button"
          onClick={() => onZoomClickHandler(0)}
          pos="absolute"
          top="3"
          left="7"
          zIndex="docked"
          w="8"
          h="8"
          boxShadow="subtleBottomGlow"
          borderRadius="full"
          bg="background.white"
        >
          <Center as="span" w="3.5" h="3.5" color="brand.primary">
            <Icon name="ui/zoom" width="100%" height="100%" />
          </Center>
        </Center>

        {badge && (
          <ProductBadge pos="absolute" top="2" right="6" zIndex="docked">
            {badge}
          </ProductBadge>
        )}

        <Box>
          {firstImage ? (
            <Box as="button" onClick={() => onZoomClickHandler(0)} mb="2" cursor="pointer" w="full">
              <AspectRatio ratio={1 / 1}>
                <Image {...firstImage} />
              </AspectRatio>
            </Box>
          ) : null}

          {formattedImages ? (
            <Grid templateColumns="repeat(2, 1fr)" gap="2">
              {formattedImages.map((image, index) => {
                if (index === 0) return null

                return (
                  <GridItem key={index} onClick={() => onZoomClickHandler(index)} cursor="pointer">
                    <AspectRatio ratio={1 / 1}>
                      <Image {...image} />
                    </AspectRatio>
                  </GridItem>
                )
              })}
            </Grid>
          ) : null}
        </Box>
      </Box>
    </>
  )
}

export default React.memo(ProductMediaGrid)
