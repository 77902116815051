import React, { useCallback, useMemo } from "react"
import { Box, Flex, IconButton, Image, Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

import { useProductContext } from "@app/providers/product"
import { useImage } from "@app/hooks/useImage"
import { Icon } from "@app/components/Icon"

SwiperCore.use([Navigation])

const ProductImageLightbox: React.FC = () => {
  const { isImageModalOpen, setIsImageModalOpen, modalImages, activeIndex, isProductDrawerOpen, labelModalOpen } = useProductContext()
  const { getGatsbyImage } = useImage()
  const formattedImages = useMemo(() => modalImages?.map(img => getGatsbyImage(img)), [modalImages, getGatsbyImage])

  const onCloseHandler = useCallback(() => setIsImageModalOpen(false), [setIsImageModalOpen])

  const id = Math.random().toString(16).slice(2)
  const prevId = `o-${id}-swiper-arrow--prev`
  const nextId = `o-${id}-swiper-arrow--next`

  const otherModalsOpen = labelModalOpen || isProductDrawerOpen

  return formattedImages?.length ? (
    <Modal isOpen={isImageModalOpen} onClose={onCloseHandler} size="full" blockScrollOnMount={!otherModalsOpen}>
      <ModalOverlay bg="background.white" />

      <IconButton
        onClick={onCloseHandler}
        variant="unstyled"
        icon={<Icon name="ui/close" width="24px" height="24px" />}
        aria-label={"Close Modal"}
        pos="fixed"
        top={{ base: "4", lg: "8" }}
        right={{ base: "4", lg: "8" }}
        bg="background.white"
        zIndex="popover"
        d="inline-flex"
        justifyContent="center"
        alignItems="center"
        w="10"
        h="10"
        borderRadius="full"
        boxShadow="subtleBottomGlow"
        _focus={{ boxShadow: "subtleBottomGlow" }}
      />

      <ModalContent maxH="100%">
        <ModalBody d="flex" flexDir="column" justifyContent="center" px={{ base: "3", md: "6" }} maxH="full" overflow="hidden">
          <Box maxW={{ base: "150", lg: "min(59.72%, 860px)" }} w="full" mx="auto">
            <Swiper
              initialSlide={activeIndex}
              loop
              slidesPerView={1}
              navigation={{ prevEl: `#${prevId}`, nextEl: `#${nextId}` }}
              simulateTouch
            >
              {formattedImages.map((image, index) => (
                <SwiperSlide key={index}>
                  <Image {...image} userSelect="none" />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </ModalBody>
      </ModalContent>

      {formattedImages.length > 1 ? (
        <Flex
          justifyContent={{ base: "center", lg: "space-between" }}
          gap={{ base: "5" }}
          pos="fixed"
          top={{ lg: "50%" }}
          bottom={{ base: "8", lg: "unset" }}
          left={{ base: "0", lg: "0" }}
          transform={{ lg: "translateY(-50%)" }}
          w="full"
          px={{ lg: "8" }}
          zIndex="popover"
        >
          <IconButton
            variant="unstyled"
            id={prevId}
            icon={<Icon name="arrows/chevron-left" width="24px" height="24px" />}
            aria-label={"Previous Image"}
            bg="background.white"
            zIndex="popover"
            d="inline-flex"
            justifyContent="center"
            alignItems="center"
            w="10"
            h="10"
            borderRadius="full"
            boxShadow="subtleBottomGlow"
            _focus={{ boxShadow: "subtleBottomGlow" }}
          />

          <IconButton
            variant="unstyled"
            id={nextId}
            icon={<Icon name="arrows/chevron-right" width="24px" height="24px" />}
            aria-label={"Next Image"}
            bg="background.white"
            zIndex="popover"
            d="inline-flex"
            justifyContent="center"
            alignItems="center"
            w="10"
            h="10"
            borderRadius="full"
            boxShadow="subtleBottomGlow"
            _focus={{ boxShadow: "subtleBottomGlow" }}
          />
        </Flex>
      ) : null}
    </Modal>
  ) : null
}

export default React.memo(ProductImageLightbox)
