import React from "react"
import { graphql, PageProps } from "gatsby"
import Template from "@app/components/Product/Product"
import { ProductProvider } from "@app/providers/product"

export type Props = PageProps<GatsbyTypes.TemplateProductQuery, GatsbyTypes.TemplateProductQueryVariables>

export const query = graphql`
  query TemplateProduct($handle: String!) {
    product: sanityProduct(shopify: { handle: { eq: $handle }, deleted: { ne: true }, published: { eq: true } }) {
      ...SanityProductFragment
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      siblings: _rawSiblings(resolveReferences: { maxDepth: 2 })
      includedProducts {
        ...SanityProductFragment
      }
      option1Collections {
        ...SanityCollectionFragment
      }
      option2Products {
        ...SanityProductFragment
      }
      giftBoxProduct {
        ...SanityProductFragment
      }
      giftBoxProductOptions {
        ...SanityCollectionFragment
      }
      accordionBlocks {
        active
        title
        content: _rawContent(resolveReferences: { maxDepth: 2 })
      }
    }
    template: sanityTemplateProduct {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      additionalProductDetailsMenuTitle
      additionalProductReviewStarsLink: _rawAdditionalProductReviewStarsLink(resolveReferences: { maxDepth: 2 })
      additionalCompareText
      additionalAddToCart
      additionalInformationItems {
        ctaIcon: _rawCtaIcon(resolveReferences: { maxDepth: 2 })
        ctaText
        drawerImage: _rawDrawerImage(resolveReferences: { maxDepth: 2 })
        drawerTitle
        drawerText
        drawerLink {
          title
          link
          external
        }
      }
      additionalInformationItemsB2B {
        ctaIcon: _rawCtaIcon(resolveReferences: { maxDepth: 2 })
        ctaText
        drawerImage: _rawDrawerImage(resolveReferences: { maxDepth: 2 })
        drawerTitle
        drawerText
        drawerLink {
          title
          link
          external
        }
      }
      videoExplainer {
        videoUrl
        title
        modalTriggerText
        enableVideoExplainer
      }
      additionalReviewsTitle
      additionalBackToProductDetails
      additionalSelectOptionLabel
      additionalSelectOptionButtonLabel
      overrideSelectOptionButtonColors
      additionalSelectOptionButtonColors {
        ...SanityCtaColor
      }
      additionalEditOptionLabel
      additionalSelectLabelLabel
      additionalSelectLabelDescription
      additionalRequiredMessage
      additionalPersonalisedButtonLabel
      overridePersonalisedButtonColors
      additionalPersonalisedButtonColors {
        ...SanityCtaColor
      }
      additionalMessagingGiftCardOptions
      overrideSelectGiftBoxButtonColors
      additionalSelectGiftBoxButtonColors {
        ...SanityCtaColor
      }
      enableQuantitySelector
      additionalSelectQuantityLabel
      additionalMultipleShippingLocationLabel
      additionalMultipleShippingLocationDescription
      enableSelectGiftBox
      additionalSelectGiftBoxLabel
      additionalSelectGiftBoxButtonLabel
      overrideSelectGiftBoxButtonColors
      additionalEditGiftBoxLabel
      customGiftBoxSelectedHeading
      customGiftBoxSelectedText
      customGiftBoxMoqText
      increaseSizeOfDoNotOpenSticker
      enableMultipleShippingLocation
      sections: _rawSections(resolveReferences: { maxDepth: 6 })
    }
    productLabels: sanitySettingProductLabels {
      ...SanityProductLabelsFragment
    }
    personaliserFields: sanityPagePersonaliser {
      step1Title
      step1Description
      step2Title
      step2Description
      step2LargeLabelTag
      step2SmallLabelTag
      step3Title
      step3Description
      step3ImageButtonLabel
      step3ImageUploadTitle
      step3ImageUploadDescription
      step3ImageUploadSmallDescription
      step3ImageUploadLimit
      step3ImageUploadErrorMessage
      step3UploadButtonLabel
      step3TextButtonLabel
      step3TextTopLineLabel
      step3TextBottomLineLabel
      step3TextMessageLabel
      step3DesignButtonLabel
      step3TextColourLabel
      step3TextColours {
        hex
        rgb {
          r
          g
          b
        }
      }
      step3BackgroundColourLabel
      step3BackgroundColours {
        hex
        rgb {
          r
          g
          b
        }
      }
      step3EditLabel
      step3Confirm
      step3LoadingMessageUpload
      step3LoadingMessageCustomise
      step3ImageUploadFailedErrorMessage
      additionalLabelSubtitle
      additionalEnlargeProduct
      additionalSelectProduct
      messagingStickerProduct {
        ...SanityProductFragment
      }
      messagingStickerTitle
      messagingStickerDescription
      messagingStickerOptions
      messagingStickerDatePlaceholder
      messagingStickerRequiredMessage
      messagingGiftCardProduct {
        ...SanityProductFragment
      }
      messagingGiftCardTitle
      messagingGiftCardDescription
      messagingGiftCardMultipleDescription
      messagingGiftCardOptions
      messagingGiftCardLabel
      messagingGiftCardMessagePlaceholder
      messagingGiftCardRequiredMessage
      additionalBuildingLabelMessage
      additionalBuildingLabelWarning
    }
    labels: allSanityPersonaliserLabel {
      edges {
        node {
          title
          labelProduct {
            ...SanityProductFragment
          }
          image: _rawImage(resolveReferences: { maxDepth: 2 })
          uuid
          customiserControls
          cropperShape
          cropperAspectRatio
          categories {
            order
            title
            handle {
              current
            }
          }
        }
      }
    }
  }
  fragment SanityColorFragment on SanityColor {
    hex
    rgb {
      r
      g
      b
    }
  }
  fragment SanityCtaColor on SanityCtaColor {
    hoverBackgroundColor {
      ...SanityColorFragment
    }
    backgroundColor {
      ...SanityColorFragment
    }
    borderColor {
      ...SanityColorFragment
    }
    textColor {
      ...SanityColorFragment
    }
  }
  fragment SanityProductFragment on SanityProduct {
    id
    url
    title
    descriptionHtml
    enableQuantitySelector
    enableSelectGiftBox
    pdpStep1CTAColour {
      ...SanityCtaColor
    }
    pdpStep2CTAColour {
      ...SanityCtaColor
    }
    pdpStep4CTAColour {
      ...SanityCtaColor
    }
    customGiftBoxSelectedHeading
    customGiftBoxSelectedText
    increaseSizeOfDoNotOpenSticker
    tags
    image
    shopify {
      id
      handle
      raw
      seoTitle
      seoDescription
    }
  }
`

function Component({ data, ...props }: Props) {
  return (
    <ProductProvider
      // giftBoxProductOptions={data.product?.giftBoxProductOptions as GatsbyTypes.SanityProductFragmentFragment[]}
      giftboxProduct={data.product?.giftBoxProduct as GatsbyTypes.SanityProductFragmentFragment}
      addOnProducts={data.product?.includedProducts as GatsbyTypes.SanityProductFragmentFragment[]}
      sanityProduct={data.product as unknown as GatsbyTypes.SanityProduct}
    >
      <Template {...props} {...data} />
    </ProductProvider>
  )
}
export default Component
