import React, { useMemo } from "react"
import { Flex } from "@chakra-ui/react"

import { NormalisedShopifyProduct, useShopifyProduct } from "@app/hooks/useShopify"
import ProductLabel from "@app/components/Product/ProductLabel"

import { MatchingLabel } from "@root/types/custom-types/Personaliser/Data"

type Props = {
  product: NormalisedShopifyProduct
  matchingLabels: MatchingLabel[] | undefined
}

const ProductLabels: React.FC<Props> = ({ product, matchingLabels }) => {
  const { getProductTagsValues } = useShopifyProduct()

  const labels = useMemo(() => getProductTagsValues("label:", product.tags), [product.tags, getProductTagsValues])

  return labels.length ? (
    <Flex gap="2" mb="5">
      {labels.map(label => (
        <ProductLabel key={label.toLowerCase()} label={label} matchingLabels={matchingLabels} />
      ))}
    </Flex>
  ) : null
}

export default React.memo(ProductLabels)
