import React from "react"
import { HStack, Text, Skeleton, Heading } from "@chakra-ui/react"
import { Helmet } from "react-helmet"

import { useShopify } from "@app/hooks/useShopify"
// import ProductComparePrice from "@app/components/Product/ProductComparePrice"
import { useProductContext } from "@app/providers/product"
import { useCheckoutContext } from "@app/providers/checkout"

type Props = {
  loading?: boolean
  compareText: string | undefined
}

function PriceText({ productBundle, compareAtPrice, bundlePrice }) {
  const { checkout } = useCheckoutContext()
  const { formatMoney } = useShopify()

  const price = formatMoney(bundlePrice || 0, checkout?.currencyCode)
  const formattedCompareAtPrice = compareAtPrice ? formatMoney(compareAtPrice || 0, checkout?.currencyCode) : null

  return (
    <Heading as={Text} size="h5" whiteSpace="nowrap" textTransform="capitalize" color="typography.headlines900">
      {!productBundle.step1 ? "from " : ""}
      {compareAtPrice && compareAtPrice > bundlePrice ? (
        <>
          <Text color="typography.body600" fontWeight="bold" fontSize="md" as="s">
            {formattedCompareAtPrice}
          </Text>{" "}
          <Text fontWeight="bold" as="span" color="functional.errorContent">
            {price}
          </Text>
        </>
      ) : (
        price
      )}
    </Heading>
  )
}

function ProductTemplatePrice({ loading, compareText }: Props) {
  const { bundlePrice, productBundle, compareAtPrice } = useProductContext()

  const isLoaded = bundlePrice !== undefined && !loading

  return (
    <>
      <Helmet>
        <script src="https://js.afterpay.com/afterpay-1.x.js" id="afterpay-placement-script" async></script>
      </Helmet>

      <Skeleton w={!isLoaded ? "45" : "auto"} h={!isLoaded ? "8" : "auto"} isLoaded={isLoaded} sx={{ "afterpay-placement": { my: "0" } }}>
        <HStack spacing="3" alignItems="center" mb={{ base: "2", lg: "2.5" }}>
          <PriceText bundlePrice={bundlePrice} productBundle={productBundle} compareAtPrice={compareAtPrice} />
          {/* <Heading as={Text} size="h5" whiteSpace="nowrap" textTransform="capitalize" color="typography.headlines900">
            {priceText}
          </Heading> */}

          {/* {compareText ? <ProductComparePrice compareText={compareText} /> : null} */}
        </HStack>

        <afterpay-placement data-locale="en_AU" data-currency="AUD" data-amount={`${bundlePrice}`} data-size="sm"></afterpay-placement>
      </Skeleton>
    </>
  )
}

export default React.memo(ProductTemplatePrice)
