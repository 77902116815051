import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Button, Fade, Flex, Heading, HStack, Skeleton, Text } from "@chakra-ui/react"

import { useCore } from "@app/hooks/useCore"
import { useGlobal } from "@app/hooks/useGlobal"
import { useMedia } from "@app/hooks/useMedia"
import { NormalisedShopifyProduct, useShopify } from "@app/hooks/useShopify"
import { useAppContext } from "@app/providers/app"
import ProductComparePrice from "@app/components/Product/ProductComparePrice"
import { useProductContext } from "@app/providers/product"
import { useCheckoutContext } from "@app/providers/checkout"

type Props = {
  product: NormalisedShopifyProduct
  loading: boolean
  compareText: string
  addToCart: string
}

const ProductStickyAddToCart: React.FC<Props> = ({ product, loading, compareText, addToCart }) => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const { headerRef, productStickyAtcRef } = useAppContext()
  const { templateAddToCartRef, addProductBundleToCart, loading: cartLoading, bundleValid, bundlePrice } = useProductContext()
  const { checkout } = useCheckoutContext()
  const { formatMoney } = useShopify()
  const { isLarge } = useMedia()
  const {
    global: { productAdd },
  } = useGlobal()

  const [stickyAtcVisible, setStickyAtcVisible] = useState(false)

  // Toggle atc visibility based on whether the standard atc component is visible or not
  const onScroll = useCallback(() => {
    if (!isBrowser || !templateAddToCartRef) return

    const rect = templateAddToCartRef.current?.getBoundingClientRect()
    if (rect === undefined) return

    const { top, height } = rect
    const atcBottom = top + height
    const headerOffset = headerRef?.current?.querySelector(".chakra-fade > div")?.getBoundingClientRect().height || 0
    const headerHeight = headerRef?.current?.getBoundingClientRect()?.height || 0
    const headerDifference = headerOffset + headerHeight
    const visibilityPoint = atcBottom - headerDifference

    if (visibilityPoint < 0) {
      setStickyAtcVisible(true)
    } else {
      setStickyAtcVisible(false)
    }
  }, [isBrowser, headerRef, templateAddToCartRef])

  useEffect(() => {
    if (!isBrowser) return

    document.addEventListener("scroll", onScroll)
    return () => document.removeEventListener("scroll", onScroll)
  }, [onScroll, isBrowser])

  /**
   * TODO: Need some logic to decide price depending on if a customer has selected an alcohol.
   * This is a bit weird because Shopify always has a variant selected. So would need to force
   * a customer to select a variant first.
   */
  const isLoaded = bundlePrice !== undefined && !loading
  const price = useMemo(() => formatMoney(bundlePrice || 0, checkout?.currencyCode), [bundlePrice, checkout?.currencyCode, formatMoney])

  const { title } = product
  const addText = useMemo(() => (!isLarge ? productAdd : addToCart.replace("{price}", price)), [isLarge, addToCart, productAdd, price])

  const onAddClickHandler = () => {
    if (templateAddToCartRef && !bundleValid) {
      const headerHeight = headerRef?.current?.getBoundingClientRect()?.height || 0
      const refTop = templateAddToCartRef?.current?.getBoundingClientRect()?.top || 0
      const yDestination = refTop + window.scrollY - headerHeight

      window.scrollTo({ top: yDestination, left: 0, behavior: "smooth" })
    } else if (bundleValid) {
      addProductBundleToCart()
    }
  }

  return (
    <Fade in={stickyAtcVisible}>
      <Box
        ref={productStickyAtcRef}
        pos="fixed"
        bottom="0"
        left="0"
        zIndex="overlay"
        w="full"
        bg="background.white"
        boxShadow="topGlow"
        pointerEvents={stickyAtcVisible ? "unset" : "none"}
      >
        <Box px={{ base: "4", lg: "20" }} py={{ base: "4", lg: "2.5" }}>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Heading as={Text} size="headlines" color="typography.headlines900" mb="0.5">
                {title}
              </Heading>

              <Skeleton w={!isLoaded ? "50" : "auto"} h={!isLoaded ? "6.5" : "auto"} isLoaded={isLoaded}>
                <HStack spacing={{ base: "1.5" }} alignItems="center">
                  <Text size="smSemiLarge">{price}</Text>

                  {compareText ? <ProductComparePrice compareText={compareText} /> : null}
                </HStack>
              </Skeleton>
            </Box>

            <Box>
              <Button onClick={onAddClickHandler} minW="23.5" isLoading={cartLoading}>
                {addText}
              </Button>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Fade>
  )
}

export default React.memo(ProductStickyAddToCart)
