import React from "react"
import {
  Box,
  Center,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerBody,
  Flex,
  Image,
  Text,
  useDisclosure,
  IconButton,
  Link,
  Heading,
  AspectRatio,
} from "@chakra-ui/react"

import { useImage } from "@app/hooks/useImage"
import { Icon } from "@app/components/Icon"
import ButtonWithIcon from "@app/components/Input/ButtonWithIcon"

export type InformationItem = {
  ctaIcon: GatsbyTypes.SanityImage
  ctaText: string
  drawerImage?: GatsbyTypes.SanityImage
  drawerTitle?: string
  drawerText: string
  drawerLink: GatsbyTypes.Maybe<Pick<GatsbyTypes.SanityLink, "title" | "link" | "external">>
}

type Props = {
  item: InformationItem
  backToProduct: string
}

const ProductInformationItem: React.FC<Props> = ({ item, backToProduct }) => {
  const { getGatsbyImage } = useImage()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const iconImage = getGatsbyImage(item.ctaIcon)
  const textBefore = item.ctaText.split("{")[0]
  const ctaButtonText = item.ctaText.split("{").pop()?.split("}")[0]
  const textAfter = item.ctaText.split("}")[1]

  const ctaText =
    ctaButtonText !== undefined ? (
      <Text size="mediumParagraph">
        {textBefore}
        <Box as="button" onClick={onOpen} textDecoration="underline">
          {ctaButtonText}
        </Box>
        {textAfter}
      </Text>
    ) : null

  const drawerImage = getGatsbyImage(item.drawerImage)

  return (
    <>
      <Flex gap="2.5">
        <Center w="5.5" h="5.5">
          <Image {...iconImage} w="full" h="full" />
        </Center>

        {ctaText}
      </Flex>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />

        <DrawerContent maxW={{ base: "80", lg: "100" }}>
          <DrawerBody p="6">
            <IconButton
              onClick={onClose}
              variant="unstyled"
              icon={<Icon name="ui/close" width="24px" height="24px" />}
              aria-label={"Close Drawer"}
              pos="absolute"
              top="4"
              right="4"
              bg="background.white"
              zIndex="overlay"
              d="inline-flex"
              justifyContent="center"
              alignItems="center"
              w="10"
              h="10"
              borderRadius="full"
              boxShadow="subtleBottomGlow"
              _focus={{ boxShadow: "subtleBottomGlow" }}
            />

            {drawerImage ? (
              <AspectRatio
                ratio={{ base: 272 / 195, lg: 352 / 252 }}
                w="full"
                mb={{ base: "5", lg: "6" }}
                borderRadius="xl"
                overflow="hidden"
              >
                <Image {...drawerImage} />
              </AspectRatio>
            ) : null}

            {item.drawerTitle ? (
              <Heading size="h4" color="typography.headlines900" mb={{ base: "2", lg: "3" }}>
                {item.drawerTitle}
              </Heading>
            ) : null}

            <Text size="largeParagraph" mb={{ base: "5", lg: "6" }} whiteSpace="pre-line">
              {item.drawerText}
            </Text>

            {item.drawerLink ? (
              <ButtonWithIcon
                as={Link}
                href={item.drawerLink.link as string}
                variant="solidSecondary"
                iconName="arrows/chevron-right"
                w="full"
                mb={{ base: "5", lg: "6" }}
              >
                {item.drawerLink.title}
              </ButtonWithIcon>
            ) : null}

            <Text as="button" onClick={onClose} size="textLinkMedium" variant="underlined">
              {backToProduct}
            </Text>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default React.memo(ProductInformationItem)
