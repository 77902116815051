import React, { useCallback } from "react"
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  IconButton,
  DrawerBody,
  AspectRatio,
  Flex,
  VStack,
  Heading,
  Box,
  Image,
  Text,
} from "@chakra-ui/react"

import { useProductContext } from "@app/providers/product"
import { useShopify, useShopifyProduct } from "@app/hooks/useShopify"
import { useImage } from "@app/hooks/useImage"
import PersonaliserProductPrice from "@app/components/Personaliser/PersonaliserProductPrice"
import ProductLabel from "@app/components/Product/ProductLabel"
import { Icon } from "@app/components/Icon"

import { MatchingLabel } from "@root/types/custom-types/Personaliser/Data"

type Props = {
  backToOptions: string
  matchingLabels?: MatchingLabel[] | undefined
}

const ProductDrawer: React.FC<Props> = ({ backToOptions, matchingLabels }) => {
  const {
    drawerProduct,
    isProductDrawerOpen,
    setIsProductDrawerOpen,
    setModalImages,
    isImageModalOpen,
    setIsImageModalOpen,
    setActiveIndex,
  } = useProductContext()
  const { getGatsbyImage } = useImage()
  const { getProductTagsValues } = useShopifyProduct()
  const { getFirstAvailableVariant } = useShopify()

  const onCloseHandler = useCallback(() => setIsProductDrawerOpen(false), [setIsProductDrawerOpen])

  const availableVariant = drawerProduct ? getFirstAvailableVariant(drawerProduct) : undefined

  if (!drawerProduct || !availableVariant) return null

  const image = getGatsbyImage(drawerProduct.images)[0]
  const labels = getProductTagsValues("label:", drawerProduct.tags)

  const onZoomHandler = () => {
    setModalImages(drawerProduct.images)
    setActiveIndex(0)
    setIsImageModalOpen(true)
  }

  const otherModalsOpen = isImageModalOpen

  return (
    <Drawer isOpen={isProductDrawerOpen} onClose={onCloseHandler} blockScrollOnMount={!otherModalsOpen}>
      <DrawerOverlay zIndex="modal" />

      <DrawerContent maxW={{ base: "80", lg: "100" }}>
        <IconButton
          onClick={onCloseHandler}
          variant="unstyled"
          icon={<Icon name="ui/close" width="24px" height="24px" />}
          aria-label={"Close Drawer"}
          pos="absolute"
          top="4"
          right="4"
          bg="background.white"
          zIndex="overlay"
          d="inline-flex"
          justifyContent="center"
          alignItems="center"
          w="10"
          h="10"
          borderRadius="full"
          boxShadow="subtleBottomGlow"
          _focus={{ boxShadow: "subtleBottomGlow" }}
        />

        <IconButton
          onClick={onZoomHandler}
          variant="unstyled"
          icon={<Icon name="ui/zoom" width="24px" height="24px" />}
          aria-label={"Close Drawer"}
          pos="absolute"
          top="4"
          left="4"
          d="inline-flex"
          color="brand.primary"
          bg="background.white"
          zIndex="overlay"
          justifyContent="center"
          alignItems="center"
          w="10"
          h="10"
          borderRadius="full"
          boxShadow="subtleBottomGlow"
          _focus={{ boxShadow: "subtleBottomGlow" }}
        />

        <DrawerBody p="6">
          <AspectRatio ratio={1 / 1} mb={{ base: "5", lg: "6" }}>
            <Image {...image} />
          </AspectRatio>

          {labels.length ? (
            <Flex gap="2" mb="4">
              {labels.map(label => (
                <ProductLabel key={label.toLowerCase()} label={label} matchingLabels={matchingLabels} />
              ))}
            </Flex>
          ) : null}

          <VStack spacing={{ base: "0", lg: "1" }} alignItems="flex-start" mb={{ base: "5", lg: "6" }}>
            <Heading size="h5" color="typography.headlines900">
              {drawerProduct.title}
            </Heading>

            {drawerProduct.vendor ? <Text size="largeParagraph">{drawerProduct.vendor}</Text> : null}

            <PersonaliserProductPrice variant={availableVariant} type="modal" />
          </VStack>

          {drawerProduct.descriptionHtml ? (
            <Text
              as={Box}
              size="largeParagraph"
              dangerouslySetInnerHTML={{ __html: drawerProduct.descriptionHtml }}
              mb={{ base: "5", lg: "6" }}
            />
          ) : null}

          <Text as="button" onClick={onCloseHandler} size="textLinkMedium">
            {backToOptions}
          </Text>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default React.memo(ProductDrawer)
