import React, { useCallback } from "react"
import { Modal, ModalOverlay, ModalContent, ModalBody, IconButton } from "@chakra-ui/react"

import { useProductContext } from "@app/providers/product"

import { Icon } from "@app/components/Icon"
import ProductLabelSelectionGrid from "@app/components/Product/ProductLabelSelectionGrid"
import Customiser from "@app/components/Personaliser/Customiser/Customiser"

import { PersonaliserLabel, ModalImage, CustomiserData, CustomLabel } from "@root/types/custom-types/Personaliser/Data"

type Props = {
  modalTitle: string
  modalDescription: string
  labelSubtitle: string
  enlargeProduct: string
  selectProduct: string
  labels: PersonaliserLabel[]
  onLabelEnlargeHandler: (images: ModalImage[] | undefined) => void
  customiserData: CustomiserData
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const ProductLabelSelectionModal: React.FC<Props> = ({
  modalTitle,
  modalDescription,
  labelSubtitle,
  enlargeProduct,
  selectProduct,
  labels,
  onLabelEnlargeHandler,
  customiserData,
  step,
  setStep,
}) => {
  const { setProductBundle, labelModalOpen, setLabelModalOpen, productBundle, setLabelLoading } = useProductContext()

  const onLabelClickHandler = useCallback(
    (item: PersonaliserLabel) => {
      setProductBundle(prevBundle => {
        return {
          ...prevBundle,
          ...{
            step2: item,
          },
        }
      })
      setStep(step => step + 1)
    },
    [setProductBundle, setStep]
  )
  const onCloseHandler = useCallback(() => setLabelModalOpen(false), [setLabelModalOpen])

  const setModalProductBundle = useCallback(
    (data: CustomLabel) => {
      setProductBundle(prevBundle => {
        return {
          ...prevBundle,
          step3: {
            ...data,
          },
        }
      })
    },
    [setProductBundle]
  )

  const onConfirmCallback = useCallback(() => onCloseHandler(), [onCloseHandler])

  return (
    <Modal onClose={onCloseHandler} isOpen={labelModalOpen} size={step === 1 ? "option1" : "editor"}>
      <ModalOverlay bg="background.overlay" />

      <ModalContent borderRadius={{ md: "xl" }} overflow={{ md: "hidden" }} maxHeight="full">
        <ModalBody p="0">
          <IconButton
            onClick={onCloseHandler}
            variant="unstyled"
            icon={<Icon name="ui/close" width="24px" height="24px" />}
            aria-label={"Close Drawer"}
            pos="absolute"
            top="4"
            right="4"
            bg="background.white"
            zIndex="overlay"
            d="inline-flex"
            justifyContent="center"
            alignItems="center"
            w="10"
            h="10"
            borderRadius="full"
            boxShadow="subtleBottomGlow"
            _focus={{ boxShadow: "subtleBottomGlow" }}
          />
          {step === 1 ? (
            <ProductLabelSelectionGrid
              modalTitle={modalTitle}
              modalDescription={modalDescription}
              labelSubtitle={labelSubtitle}
              enlargeProduct={enlargeProduct}
              selectProduct={selectProduct}
              labels={labels}
              onLabelClickHandler={onLabelClickHandler}
              onCloseHandler={onCloseHandler}
              onLabelEnlargeHandler={onLabelEnlargeHandler}
            />
          ) : (
            <Customiser
              bundle={productBundle}
              setBundle={setModalProductBundle}
              data={customiserData}
              setLabelLoading={setLabelLoading}
              onConfirmCb={onConfirmCallback}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
export default React.memo(ProductLabelSelectionModal)
