import React from "react"
import { Heading } from "@chakra-ui/react"

import { NormalisedShopifyProduct } from "@app/hooks/useShopify"

type Props = {
  product: NormalisedShopifyProduct
}

const ProductTitle: React.FC<Props> = ({ product }) => (
  <Heading as="h1" size="h3" color="typography.headlines900" mb={{ base: "2", lg: "2.5" }}>
    {product?.title}
  </Heading>
)

export default React.memo(ProductTitle)
