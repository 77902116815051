import React from "react"
import { Box, Container, Flex, Heading, IconButton, Skeleton } from "@chakra-ui/react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

import { withSection } from "@app/hoc/Section"
import ReviewItemLite from "@app/components/Reviews/ReviewItemLite"
import { Icon } from "@app/components/Icon"

SwiperCore.use([Navigation])

export type ReviewTheme = "primary" | "secondary"
type ThemeColor = {
  primary: string
  secondary: string
}
export type SectionColors = {
  heading: ThemeColor
  body: ThemeColor
  background: ThemeColor
  dotActive: ThemeColor
  dotDefault: ThemeColor
}

type Props = {
  id: string
  title: string
  theme?: ReviewTheme
  reviewsResponse?: any
}

const ReviewsLite: React.FC<Props> = ({ id, title, theme = "primary", reviewsResponse }) => {
  const prevId = `o-${id}-swiper-arrow--prev`
  const nextId = `o-${id}-swiper-arrow--next`

  const sectionColors = {
    heading: {
      primary: "background.white",
      secondary: "typography.headlines900",
    },
    body: {
      primary: "background.white",
      secondary: "typography.body700",
    },
    background: {
      primary: "brand.secondaryFocus",
      secondary: "background.beige",
    },
    dotActive: {
      primary: "background.white",
      secondary: "brand.primary",
    },
    dotDefault: {
      primary: "rgba(255, 255, 255, 0.5)",
      secondary: "border.default",
    },
  }

  return (
    <Box bg={sectionColors.background[theme]} textAlign="center" borderRadius="xl" overflow="hidden">
      <Container maxW="container.4xl" px="5" py="8">
        <Flex mb="5" justifyContent="space-between" alignItems="center">
          <Heading size="h3" color={sectionColors.heading[theme]} textAlign="left">
            {title}
          </Heading>

          <Flex gap="4" color="background.white">
            <IconButton
              variant="unstyled"
              aria-label="Previous"
              icon={<Icon name="arrows/chevron-left" width="24" height="24" />}
              id={prevId}
              pointerEvents="initial"
            />
            <IconButton
              variant="unstyled"
              aria-label="Next"
              icon={<Icon name="arrows/chevron-right" width="24" height="24" />}
              id={nextId}
              pointerEvents="initial"
            />
          </Flex>
        </Flex>

        <Box
          sx={{
            ".swiper-slide": { bg: "transparent", justifyContent: "flex-start" },
          }}
        >
          <Swiper lazy loop simulateTouch slidesPerView={1} autoHeight={true} navigation={{ prevEl: `#${prevId}`, nextEl: `#${nextId}` }}>
            {reviewsResponse && reviewsResponse?.reviews?.length ? (
              reviewsResponse.reviews.map((review, index) => (
                <SwiperSlide key={index}>
                  <ReviewItemLite review={review} theme={theme} sectionColors={sectionColors} />
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide>
                <Skeleton w="full" h="50" />
              </SwiperSlide>
            )}
          </Swiper>
        </Box>
      </Container>
    </Box>
  )
}

export default React.memo(withSection(ReviewsLite))
