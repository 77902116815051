import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { AspectRatio, Box, Center, Image } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Swiper as SwiperType } from "swiper"
import "swiper/css"

import { NormalisedImage, NormalisedShopifyProduct, useShopifyProduct } from "@app/hooks/useShopify"
import { useImage } from "@app/hooks/useImage"
import ProductBadge from "@app/components/Product/ProductBadge"
import { Icon } from "@app/components/Icon"
import { useProductContext } from "@app/providers/product"

type Props = {
  product: NormalisedShopifyProduct
  images: NormalisedImage[]
}

const ProductMediaSwiper: React.FC<Props> = ({ product, images }) => {
  const { setIsImageModalOpen, setActiveIndex, setModalImages } = useProductContext()
  const { getGatsbyImage } = useImage()
  const { getProductTagValue } = useShopifyProduct()

  const swiperRef = useRef<SwiperType | null>(null)

  useEffect(() => {
    if (swiperRef.current?.realIndex === undefined) return
    setActiveIndex(swiperRef.current?.realIndex)
  }, [swiperRef.current?.realIndex, setActiveIndex])

  const onSwiperHandler = useCallback((swiper: SwiperType) => (swiperRef.current = swiper), [swiperRef])
  const onZoomClickHandler = useCallback(() => {
    if (swiperRef.current?.realIndex === undefined) return
    setModalImages(images)
    setIsImageModalOpen(true)
    setActiveIndex(swiperRef.current?.realIndex)
  }, [swiperRef, setModalImages, images, setIsImageModalOpen, setActiveIndex])

  const badge = useMemo(() => getProductTagValue("badge:", product.tags), [getProductTagValue, product.tags])

  return (
    <>
      <Box pos="relative" sx={{ ".swiper": { alignItems: "stretch" } }}>
        <Center
          as="button"
          onClick={onZoomClickHandler}
          pos="absolute"
          top="3"
          left="7"
          zIndex="overlay"
          w="8"
          h="8"
          boxShadow="subtleBottomGlow"
          borderRadius="full"
          bg="background.white"
        >
          <Center as="span" w="3.5" h="3.5" color="brand.primary">
            <Icon name="ui/zoom" width="100%" height="100%" />
          </Center>
        </Center>

        {badge && (
          <ProductBadge pos="absolute" top="2" right="6" zIndex="docked">
            {badge}
          </ProductBadge>
        )}

        <Swiper
          loop
          slidesPerView={1.09}
          spaceBetween={4}
          centeredSlides
          onInit={onSwiperHandler}
          breakpoints={{ 540: { slidesPerView: 1.8 }, 700: { slidesPerView: 2 } }}
        >
          {images.map((item, index) => {
            const image = getGatsbyImage(item)

            return (
              <SwiperSlide key={index} onClick={onZoomClickHandler}>
                <AspectRatio ratio={1 / 1} w="full">
                  <Image {...image} />
                </AspectRatio>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Box>
    </>
  )
}

export default React.memo(ProductMediaSwiper)
