import React from "react"
import { Box, Text } from "@chakra-ui/react"

import { NormalisedShopifyProduct } from "@app/hooks/useShopify"

type Props = {
  product: NormalisedShopifyProduct
}

const ProductDescription: React.FC<Props> = ({ product }) =>
  product?.description ? (
    <Box py={{ base: "5", lg: "6" }} px={{ base: "4", lg: "0" }}>
      <Text size="largeParagraph">{product?.description}</Text>
    </Box>
  ) : null

export default React.memo(ProductDescription)
